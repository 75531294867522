import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { FetchWithAuth } from "digimaker-ui/util";
import { ShowPageUrl } from "../ShowPageUrl";

const ShowUrl = (props: any) => {
  const {
    params: { content },
  } = props;

  return <ShowPageUrl content={content} />;
};

export { ShowUrl };
