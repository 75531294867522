import { DMEData, iterateBlockList } from "dmeditor";
import { siteEnv } from "./siteEnv";
import { nanoid } from "nanoid";

export const getNiceUrl = (identifierPath: string) => {
  let arr = identifierPath.split("/");
  return wrapBasePath(arr.slice(2).join("/"));
};

export const wrapBasePath = (url: string) => {
  if (siteEnv.basePath) {
    return "/" + siteEnv.basePath + url;
  } else {
    return url;
  }
};

export const getArticleNiceUrl = (location: any) => {
  const arr = location.identifier_path.split("/");
  let niceUrl = arr[arr.length - 1];
  return wrapBasePath("/detail/" + niceUrl + "/" + location.id);
};

export const getIdentifierPath = (path: string) => {
  return "content/hjerter-ess/" + path;
};

export const imagePath = (path, thumbnail?: boolean) => {
  if (
    path.startsWith("http://") ||
    path.startsWith("https://") ||
    path.startsWith("data:")
  ) {
    return path;
  } else {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      return (
        "http://adzt2clavc.dcp.dev.digimaker.no/var/" +
        (thumbnail ? "images/thumbnail/" : "images/full/") +
        path
      );
    } else {
      return (
        "https://bridge.c.digimaker.com/var/" +
        (thumbnail ? "images/thumbnail/" : "images/full/") +
        path
      );
    }
  }
};

export const serverFetchWithAuth = async (bodyObj: any) => {
  try {
    const res = await fetch(`${process.env.graphqlUrl}`, {
      headers: { apiKey: `${process.env.apiKey}` },
      method: "POST",
      body: JSON.stringify({ query: `${bodyObj}` }),
    });
    const data = await res.json();
    return data;
  } catch (e) {
    console.error("Widget fetching error on", bodyObj);
    throw e;
  }
};

//convert old to new
export const convertObject = (data: DMEData.Block[]) => {
  iterateBlockList(data as any, (blockitem: any) => {
    if (blockitem.type === "hero-text" && Array.isArray(blockitem.children)) {
      const children = blockitem.children;
      if (children[0] && children[1]) {
        blockitem.children = { hero: children[0], list: children[1] } as any;
      } else if (children.length === 1) {
        if (children[0].type === "list") {
          blockitem.children = {
            hero: {
              type: "image",
              data: { src: "", settings: {} },
              isEmbed: true,
            },
            list: children[0],
          } as any;
        } else if (children[0].type === "image") {
          blockitem.children = {
            hero: children[0],
            list: { type: "list", data: {}, children: [], isEmbed: true },
          };
        }
      } else {
        blockitem.children = {
          hero: {
            type: "image",
            data: { src: "", settings: {} },
            isEmbed: true,
          },
          list: { type: "list", data: {}, children: [], isEmbed: true },
        } as any;
      }
    }

    if (
      blockitem &&
      blockitem.type === "layout-2columns" &&
      Array.isArray(blockitem.children)
    ) {
      const children = blockitem.children;
      if (children[0] && children[1]) {
        blockitem.children = {
          column1: {
            id: nanoid(),
            type: "list",
            data: {},
            ...children[0],
          },
          column2: { id: nanoid(), type: "list", data: {}, ...children[1] },
        } as any;
      }
    }

    if (
      blockitem &&
      blockitem.type === "layout-3columns" &&
      Array.isArray(blockitem.children)
    ) {
      const children = blockitem.children;
      if (children[0] && children[1] && children[2]) {
        blockitem.children = {
          column1: { id: nanoid(), type: "list", data: {}, ...children[0] },
          column2: { id: nanoid(), type: "list", data: {}, ...children[1] },
          column3: { id: nanoid(), type: "list", data: {}, ...children[2] },
        } as any;
      }
    }
  });

  return data;
};
