import { useMemo } from "react";
import { SpillInputData } from "./entity";
import styled from "@emotion/styled";
import { css } from "@emotion/css";

const BoardTable = styled.table<{ selected?: boolean }>`
  border: 1px solid #333333;
  width: var(--bridge-spill-board-width);
  height: var(--bridge-spill-board-width);

  background-repeat: no-repeat;
  background-size: contain;

  td {
    width: 33.33%;
    text-align: center;
  }
`;

const BoardContainer = styled.div`
  --bridge-spill-board-width: 130px;
  position: relative;
`;

const BoardText = styled.span<{ isGiver?: boolean; isZone?: boolean }>`
  font-weight: bold;
  ${(props) => (props.isZone ? { color: "white" } : {})};
  font-size: 18px;
  ${(props) =>
    props.isGiver
      ? {
          background: "black",
          display: "inline-block",
          borderRadius: "50%",
          width: 24,
          lineHeight: "24px",
          color: "white",
        }
      : {}}
`;

const BoardNo = styled.span`
  text-align: center;
  background: white;
  outline: 1px solid black;
  border-radius: 50%;
  width: 40px;
  line-height: 40px;
  font-weight: bold;
  display: inline-block;
`;

export const Board = (props: {
  editMode?: boolean;
  zone: SpillInputData["zone"];
  giver: SpillInputData["giver"];
  boardNo: string;
  onBoardNoChange: (v: string) => void;
}) => {
  const { giver, zone, editMode } = props;
  const bg = useMemo(() => {
    return "/images/board-bg-" + zone + ".svg";
  }, [zone]);

  return (
    <BoardContainer>
      <BoardTable style={{ backgroundImage: "url(" + bg + ")" }}>
        <tr>
          <td></td>
          <td>
            <BoardText
              isGiver={giver === "n"}
              isZone={zone === "a" || zone === "ns"}
            >
              N
            </BoardText>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <BoardText
              isGiver={giver === "w"}
              isZone={zone === "a" || zone === "ew"}
            >
              V
            </BoardText>
          </td>
          <td>
            {editMode && (
              <BoardNo>
                <input
                  value={props.boardNo}
                  placeholder="X"
                  onChange={(e) => props.onBoardNoChange(e.target.value)}
                  className={css`
                    width: 1.5rem;
                    text-align: center;
                    height: 1.5rem;
                    border: 1px solid #cccccc;
                  `}
                />
              </BoardNo>
            )}
            {!editMode && props.boardNo && <BoardNo>{props.boardNo}</BoardNo>}
          </td>
          <td>
            <BoardText
              isGiver={giver === "e"}
              isZone={zone === "a" || zone === "ew"}
            >
              Ø
            </BoardText>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <BoardText
              isGiver={giver === "s"}
              isZone={zone === "a" || zone === "ns"}
            >
              S
            </BoardText>
          </td>
          <td></td>
        </tr>
      </BoardTable>
    </BoardContainer>
  );
};
