import { DME } from "dmeditor";
import { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { EntityClubInfo } from "./entity";
import { siteEnv } from "../siteEnv";

const ClubInfo = (props: DME.WidgetRenderProps<EntityClubInfo>) => {
  const {
    blockNode: { data },
  } = props;

  const [value, setValue] = useState(data.value);

  const clubID = siteEnv.clubID;
  const fetchData = () => {
    fetch(`https://www.bridge.no/bridgemodule/klubb_json?klubbid=${clubID}`)
      .then((res) => res.json())
      .then((data) => {
        setValue(data);
      });
  };

  useEffect(() => {
    if (!props.blockNode.serverData) {
      fetchData();
    }
  }, []);

  if (!value) {
    return <div></div>;
  }

  return (
    <div className={"row-top-space"}>
      <div className="container">
        <h3>{value.name}</h3>

        <br />

        <Row>
          <Col md={2}>
            <label>Hjemmeside: </label>
          </Col>
          <Col>
            <a href={value.url}>{value.url}</a>
          </Col>
        </Row>

        <Row>
          <Col md={2}>
            <label>E-post:</label>
          </Col>
          <Col>
            <a href={"mailto:" + value.e_post}>{value.e_post}</a>
          </Col>
        </Row>

        <Row>
          <Col md={2}>
            <label>Klubbnummer:</label>
          </Col>
          <Col>{value.klubbnummer}</Col>
        </Row>

        <br />
        <Row>
          <Col md={2}>
            <label>Stiftet dato: </label>
          </Col>
          <Col>{value.klubb_dato}</Col>
        </Row>
        <Row>
          <Col md={2}>
            <label>Spillested:</label>
          </Col>
          <Col>{value.spillested}</Col>
        </Row>
        <Row>
          <Col md={2}>
            <label>Spilledag:</label>
          </Col>
          <Col>{value.spilledag}</Col>
        </Row>

        <br />
        <Row>
          <Col md={2}>
            <label>Kontonr. klubb:</label>
          </Col>
          <Col>{value.kontonr}</Col>
        </Row>
        <Row>
          <Col md={2}>
            <label>Orgnr. klubb:</label>
          </Col>
          <Col>{value.orgnr}</Col>
        </Row>

        {value._leader && (
          <div>
            <br />
            <h4>Leder</h4>
            <Row>
              <Col md={2}>
                <label>Leder:</label>
              </Col>
              <Col>
                {value._leader.first_name} {value._leader.last_name}
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label>E-post:</label>
              </Col>
              <Col>
                <a href={"mailto:" + value._leader.user_email2}>
                  {value._leader.user_email2}
                </a>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label>Mobil:</label>
              </Col>
              <Col>
                <a href={"tel:" + value._leader.user_pohonemobile}>
                  {value._leader.user_pohonemobile}
                </a>
              </Col>
            </Row>
          </div>
        )}

        {value._kasserer && (
          <div>
            <br />
            <h4>Kasserer</h4>
            <Row>
              <Col md={2}>
                <label>Kasserer:</label>
              </Col>
              <Col>
                {value._kasserer.first_name} {value._kasserer.last_name}
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label>E-post:</label>
              </Col>
              <Col>
                <a href={"mailto:" + value._kasserer.user_email2}>
                  {value._kasserer.user_email2}
                </a>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <label>Mobil:</label>
              </Col>
              <Col>
                <a href={"tel:" + value._leader.user_pohonemobile}>
                  {value._kasserer.user_pohonemobile}
                </a>
              </Col>
            </Row>
            <br />
            <br />
          </div>
        )}
      </div>
    </div>
  );
};

const onServerSideLoad = async (blockData, context) => {
  const { siteEnv } = context;
  const clubID = siteEnv.clubID;
  const res = await fetch(
    `https://www.bridge.no/bridgemodule/klubb_json?klubbid=${clubID}`
  );
  const jsonData = await res.json();
  blockData.data.value = jsonData;
  blockData.serverData = true;
};

export { ClubInfo, onServerSideLoad };
