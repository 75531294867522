import { DME } from "dmeditor";
import { useEffect, useState } from "react";

import { EntityMemberList } from "./entity";
import { Alert, Col, Row, Table } from "react-bootstrap";
import { siteEnv } from "../siteEnv";

const MemberList = (props: DME.WidgetRenderProps<EntityMemberList>) => {
  const {
    blockNode: { data: blockData },
  } = props;

  const [value, setValue] = useState(blockData.value || {});

  let data = value.html;

  const [shownMembers, setShownMembers] = useState(
    data ? data.members || [] : []
  );

  const [sortby, setSortby] = useState("name"); //name/mp/hcp/number/
  const [sortbyOrder, setSortbyOrder] = useState(true); //true-asc, false-desc

  const fetchData = () => {
    const clubID = siteEnv.clubID;
    const url = `https://www.bridge.no/bridgemodule/medlemmer_json?klubbid=${clubID}&limit=1000&sortMethod=name&sortType=0&offset=0&returnType=json&returnArray=true`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        for (const i in data.html.members) {
          data.html.members[i]["_membertype"] =
            data.html.memberships[data.html.members[i]["user_membernumber"]];
        }
        setValue(data);
        setShownMembers(data.html.members);
      });
  };

  useEffect(() => {
    if (!props.blockNode.serverData) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    let sortedMembers = [...shownMembers];
    sortedMembers.sort((a, b) => {
      let aValue = a[sortby];
      let bValue = b[sortby];
      if (sortby !== "last_name" && sortby != "_membertype") {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }
      if (aValue > bValue) {
        return sortbyOrder ? 1 : -1;
      } else if (aValue < bValue) {
        return sortbyOrder ? -1 : 1;
      } else {
        return 0;
      }
    });
    setShownMembers(sortedMembers);
  }, [sortby, sortbyOrder]);

  const filter = (e) => {
    const input = e.target.value.toLowerCase();
    const allMembers = data.members;
    const filteredMembers = allMembers.filter((item) => {
      if (
        item.first_name.toLowerCase().includes(input) ||
        item.last_name.toLowerCase().includes(input)
      ) {
        return true;
      } else {
        return false;
      }
    });
    setShownMembers(filteredMembers);
  };

  const sorting = (e, sortbyIdentifier: string) => {
    e.preventDefault();
    if (sortby === sortbyIdentifier) {
      setSortbyOrder(!sortbyOrder);
    } else {
      setSortby(sortbyIdentifier);
      setSortbyOrder(true);
    }
  };

  const renderSortText = (text: string, sortbyIdentifier: string) => {
    return (
      <a href="#" onClick={(e) => sorting(e, sortbyIdentifier)}>
        {text}{" "}
        {sortby === sortbyIdentifier && (
          <i
            className={"bi bi-caret-" + (sortbyOrder ? "up" : "down") + "-fill"}
          ></i>
        )}
      </a>
    );
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white container">
      <Row className="mb-4 member-stat">
        <Col className="col-2 col-md-1">
          <label>Antall.</label>
          <div>{data.m_tot}</div>
        </Col>
        <Col className="col-2  col-md-1">
          <label>Std.</label>
          <div>{data.m_std}</div>
        </Col>
        <Col className="col-2  col-md-1">
          <label>Jr.</label>
          <div>{data.m_junior}</div>
        </Col>
        <Col className="col-2  col-md-1">
          <label>I.</label>
          <div>{data.m_k}</div>
        </Col>
        <Col className="col-2  col-md-1">
          <label>Lisens(m/lisens).</label>
          <div>{data.m_lisens}</div>
        </Col>
      </Row>

      <div className="right">
        <Row>
          <Col className="col-9"></Col>
          <Col className="col-12 col-md-3">
            <input
              className="form-control mb-4"
              placeholder="Filter navn"
              onKeyUp={filter}
            ></input>
          </Col>
        </Row>
      </div>

      {shownMembers.length > 0 && (
        <Table striped className="table-memberlist">
          <thead>
            <tr>
              <th>{renderSortText("Navn", "last_name")}</th>
              <th>{renderSortText("Spillernr.", "user_membernumber")}</th>
              <th>{renderSortText("Medlemstype", "_membertype")}</th>
              <th>{renderSortText("MP", "user_mp")}</th>
              <th>{renderSortText("HCP", "user_hcp")}</th>
            </tr>
          </thead>
          <tbody>
            {shownMembers.map((member) => (
              <tr>
                <td>
                  <a
                    href={"https://www.bridge.no/" + member.url_alias}
                    target="_blank"
                  >
                    {member.last_name}, {member.first_name}{" "}
                    <i className="bi bi-box-arrow-up-right"></i>
                  </a>
                </td>
                <td>{member.user_membernumber}</td>
                <td>{member["_membertype"]}</td>
                <td>{member.user_mp}</td>
                <td>{member.user_hcp}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {shownMembers.length === 0 && (
        <Alert variant="warning">Ingen medlem funnet.</Alert>
      )}
    </div>
  );
};

const onServerSideLoad = async (blockData, context) => {
  const { siteEnv } = context;
  const clubID = siteEnv.clubID;
  const res = await fetch(
    `https://www.bridge.no/bridgemodule/medlemmer_json?klubbid=${clubID}&limit=1000&sortMethod=name&sortType=0&offset=0&returnType=json&returnArray=true`
  );
  const jsonData = await res.json();
  for (const i in jsonData.html.members) {
    jsonData.html.members[i]["_membertype"] =
      jsonData.html.memberships[jsonData.html.members[i]["user_membernumber"]];
  }
  blockData.data.value = jsonData;
  blockData.serverData = true;
};

export { MemberList, onServerSideLoad };
