import { registerIcon, setDMEditorCallback } from "dmeditor";
import { BrowseImage } from "./components/dmeditor-callbacks/BrowseImage";
import { BrowseLink } from "./components/dmeditor-callbacks/BrowseLink";

import { dmeditorInit } from "shared/src/DMEditorInit";
import { nanoid } from "nanoid";
import "shared/src/public-common.css";
import {
  fetchInClient,
  SelectContent,
} from "./components/dmeditor-callbacks/SelectContent";

setDMEditorCallback({
  browseImage: BrowseImage,
  browseLink: BrowseLink,
  getSavedBlocks: (widget: string) => {
    switch (widget) {
      case "heading":
        return [
          {
            name: "Bridge title",
            savedData: {
              id: nanoid(),
              type: "heading",
              style: { type: "bridge-title" },
              data: {
                level: 2,
                value: "Heading",
                settings: {},
              },
            },
          },
        ];
      default:
        return [];
    }
  },
});

registerIcon({
  name: "bridge",
  component: () => <img height="20px" alt="" src="/logo.png" />,
});

dmeditorInit({
  extraConfig: {
    dataSource: { edit: SelectContent, fetchInClient: fetchInClient },
  },
});
