import { useParams } from "react-router";
import { useEffect, useMemo, useRef, useState } from "react";
import { FetchWithAuth } from "digimaker-ui/util";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Alert, Button, Container, Overlay } from "react-bootstrap";
import { ContentLibrary } from "./ContentLibrary";
import useSiteStore from "../../store/useSiteStore";
import { DMEditorView } from "dmeditor";
import { Delete } from "digimaker-ui/actions";
import { convertObject } from "shared/src/widgets/util";
import { List } from "digimaker-ui";
import { getListConfig } from "../../Config";
import { ButtonNewPage } from "../../components/ButtonNewPage";

export const Pages = (props: { id: number }) => {
  const navigate = useNavigate();
  const [content, setContent] = useState(null as any);
  const { setCurrentContent, user } = useSiteStore();
  const {
    site: { host, basePath },
  } = useSiteStore();

  useEffect(() => {
    FetchWithAuth("content/get/" + props.id).then((resp: any) => {
      if (resp.error === false) {
        const content = resp.data;
        setContent(content);
        setCurrentContent(null);
      } else {
        setContent(null);
      }
    });
  }, [props.id]);

  if (!content) {
    return <div>...</div>;
  }

  return (
    <div>
      <h2 className="title">Sider</h2>
      <div style={{ float: "right" }}>
        <ButtonNewPage from="/pages" />
      </div>
      <List
        id={props.id}
        key={props.id}
        contenttype={"page"}
        {...getListConfig(content, "page")}
      />
    </div>
  );
};
