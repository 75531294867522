import { GridViewOutlined } from "@mui/icons-material";

import { NyheterDiv } from "../commonStyle";
import { Button as BootButton } from "react-bootstrap";

import { FetchWithAuth } from "digimaker-ui/util";
import { useEffect, useState } from "react";
import { getArticleNiceUrl, imagePath, serverFetchWithAuth } from "../util";
import { EntityTopNews } from "./entity";
import { DME } from "dmeditor";
import { css } from "@emotion/css";
import { siteEnv } from "../siteEnv";

const TopNews = (props: DME.WidgetRenderProps<EntityTopNews>) => {
  const {
    blockNode: {
      data,
      data: {
        settings: { limit },
      },
    },
  } = props;
  const sortby = ["priority%20desc", "published%20desc"];

  const [list, setList] = useState(data.value || []);

  const fetchData = () => {
    const parentID = siteEnv.folderNews;
    FetchWithAuth(
      `content/list/article?parent=${parentID}&location.is_hidden=false&limit=10&sortby=${sortby.join(
        "%3B"
      )}`
    ).then((resp: any) => {
      if (resp.error === false) {
        setList(resp.data.list);
      }
    });
  };

  const seeDetail = (location: any) => {
    window.location.href = getArticleNiceUrl(location);
  };

  useEffect(() => {
    if (!props.blockNode.serverData) {
      fetchData();
    }
  }, []);

  const disableLinkStyle =
    props.mode === "edit" ? css({ pointerEvents: "none" }) : "";

  return (
    <NyheterDiv className="nyheterDiv">
      <div className="nyhetercontent">
        <div className={`dm-columns columns-${limit} content-group`}>
          {list.map((item: any, index) =>
            index < limit ? (
              <div
                className="columns-item"
                key={item.id}
                onClick={() => {
                  if (props.mode !== "edit") {
                    seeDetail(item.location);
                  }
                }}
              >
                <div
                  className="columns-item-img"
                  style={
                    item.coverimage == "" ? { border: "1px solid #ccc" } : {}
                  }
                >
                  <img
                    src={
                      item.coverimage
                        ? imagePath(item.coverimage)
                        : "/images/bg2.jpg"
                    }
                    style={{ maxHeight: "100%" }}
                    width={"100%"}
                  />
                </div>
                <div className="columns-item-details">
                  <div className="details-title font-Cambria-blod">
                    {item.title}
                  </div>
                  <div
                    className="details-content"
                    dangerouslySetInnerHTML={{ __html: item.summary }}
                  ></div>
                </div>
              </div>
            ) : (
              <></>
            )
          )}
        </div>
        {list.length > 0 && (
          <div className="text-align moreBtn">
            <BootButton
              className={"font-Cambria-blod " + disableLinkStyle}
              size="lg"
              variant="success"
              href={data.moreLink}
            >
              FLERE
            </BootButton>
          </div>
        )}
      </div>
    </NyheterDiv>
  );
};

const onServerSideLoad = async (blockData: any, context) => {
  const { siteEnv } = context;

  const data = blockData.data;
  const limit = data.settings.limit;

  const parent = siteEnv.folderNews;
  const bodyObj = `query{
      article(filter:{_location_parent_id:${parent}, _location_is_hidden:false},limit:${limit}, sort:["priority desc","is_archived asc", "ref desc", "id desc"]){
        id,
        title,
        coverimage,
        summary,
        location{id,hierarchy,content_type,identifier_path, parent_id}, 
    }
    }`;
  const jsonData = await serverFetchWithAuth(bodyObj);
  blockData.data.value = jsonData?.data?.article;
  blockData.serverData = true;
};

export { TopNews, onServerSideLoad };
