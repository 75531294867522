import { DataSourceConfigType } from "dmeditor/core/config";
import { Browse } from "digimaker-ui";
import { FetchWithAuth } from "digimaker-ui/util";
import useSiteStore from "../../store/useSiteStore";

export const SelectContent: DataSourceConfigType["edit"] = (props) => {
  const confirm = (content) => {
    const data = props.data;
    const id = content.id;
    const contentType = content.metadata.contenttype;
    const name = content.metadata.name;
    const newData = { ...data, type: contentType, id: id, name: name };
    props.onChange(newData);
  };

  const { site } = useSiteStore();

  return (
    <div>
      <Browse
        contenttype={["block"]}
        parent={site.id}
        trigger={true}
        inline={true}
        multi={props.isList}
        onConfirm={confirm}
      />
    </div>
  );
};

export const fetchInClient = async (widget, data, extraData) => {
  let id = 0;
  if (data.type === "fixed") {
    const source = data.sourceData;
    if (source.id) {
      id = source.id;
    }
  } else if (data.type === "dependency") {
    id = extraData.dependencyValue;
  }
  if (id) {
    const result = await FetchWithAuth("content/get/block/" + id);
    const content = result.data.content;
    return content;
  }

  return null;
};
