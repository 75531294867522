import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CSS } from "@dnd-kit/utilities";
import { FetchWithAuth } from "digimaker-ui/util";
import useSiteStore from "../../store/useSiteStore";

const SortableMenu = (props: { list: Array<any>; onRefresh: () => void }) => {
  const [list, setList] = useState(props.list);
  const [started, setStarted] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    setStarted(false);
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = list.findIndex((item) => item.id === active.id);
      const newIndex = list.findIndex((item) => item.id === over.id);
      const newList = arrayMove(list, oldIndex, newIndex);
      const oldList = list;
      setList(newList);

      let priority = 1000;
      const values: Array<string> = [];

      newList.forEach((item) => {
        values.push(item.location.id + "," + priority);
        priority -= 100;
      });
      FetchWithAuth("content/setpriority?params=" + values.join("%3B")).then(
        (data) => {
          if (data.error === false) {
            props.onRefresh();
          } else {
            window.alert("Saving error.");
            setList(oldList);
          }
        }
      );
    }
  };

  const {
    site: { id: siteID },
  } = useSiteStore();

  useEffect(() => {
    setList(props.list);
  }, [props.list]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={() => setStarted(true)}
    >
      <SortableContext items={list} strategy={horizontalListSortingStrategy}>
        <ul className="submenu">
          <li style={{ paddingLeft: 10 }}>
            <NavLink to={"/website/main/" + siteID}>Forside</NavLink>
          </li>
          {list.map((item) => (
            <>
              <SortableItem id={item.id} key={item.id}>
                <i className="bi bi-grip-vertical"></i>
                {started && <a href="#">{item.metadata.name}</a>}
                {/* Note: dnd-kit disables NavLink to use a if NavLink is inside SortableItem */}
              </SortableItem>
              {!started && (
                <li>
                  <NavLink
                    to={"main/" + item.location.id}
                    style={item.hide_in_menu ? { color: "#333333" } : {}}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {item.metadata.name}
                  </NavLink>
                </li>
              )}
            </>
          ))}
        </ul>
      </SortableContext>
    </DndContext>
  );
};

const SortableItem = (props: { id; children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString({
      scaleX: 1,
      scaleY: 1,
      x: transform?.x || 0,
      y: transform?.y || 0,
    }),
    transition,
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="sort-item"
    >
      {props.children}
    </li>
  );
};

export { SortableMenu };
