import { DMEditorView } from "dmeditor";
import { convertObject } from "../widgets/util";

export const ContentViewRender = (props: { data }) => {
  const body = props.data ? props.data : null;

  if (!body) {
    return <div>No data found.</div>;
  }
  return (
    <div>
      {typeof body === "string" && (
        <div dangerouslySetInnerHTML={{ __html: body }}></div>
      )}
      {Array.isArray(body) && <DMEditorView data={convertObject(body || [])} />}
    </div>
  );
};
