import { SpillInputData } from "./entity";
import { GiverRow, GiverTable, GiverItem } from "./style";

export const GiverZone = (props: {
  giver: SpillInputData["giver"];
  zone: SpillInputData["zone"];
  mode: "edit" | "view";
  onGiverChange: (giver: SpillInputData["giver"]) => void;
  onZoneChange: (giver: SpillInputData["zone"]) => void;
}) => {
  const { giver, zone, onGiverChange, onZoneChange } = props;
  return (
    <div>
      <GiverRow>
        <label>Giver</label>
        <GiverTable>
          <tr>
            <GiverItem
              onClick={() => onGiverChange("n")}
              selected={giver === "n"}
              editMode={props.mode === "edit"}
            >
              N
            </GiverItem>
            <GiverItem
              onClick={() => onGiverChange("e")}
              editMode={props.mode === "edit"}
              selected={giver === "e"}
            >
              Ø
            </GiverItem>
            <GiverItem
              onClick={() => onGiverChange("s")}
              editMode={props.mode === "edit"}
              selected={giver === "s"}
            >
              S
            </GiverItem>
            <GiverItem
              onClick={() => onGiverChange("w")}
              editMode={props.mode === "edit"}
              selected={giver === "w"}
            >
              V
            </GiverItem>
          </tr>
        </GiverTable>
      </GiverRow>
      <GiverRow>
        <label>Sone</label>
        <GiverTable>
          <tr>
            <GiverItem
              onClick={() => onZoneChange("i")}
              editMode={props.mode === "edit"}
              selected={zone === "i"}
            >
              I
            </GiverItem>
            <GiverItem
              onClick={() => onZoneChange("ns")}
              editMode={props.mode === "edit"}
              selected={zone === "ns"}
            >
              NS
            </GiverItem>
            <GiverItem
              onClick={() => onZoneChange("ew")}
              editMode={props.mode === "edit"}
              selected={zone === "ew"}
            >
              ØV
            </GiverItem>
            <GiverItem
              onClick={() => onZoneChange("a")}
              editMode={props.mode === "edit"}
              selected={zone === "a"}
            >
              A
            </GiverItem>
          </tr>
        </GiverTable>
      </GiverRow>
    </div>
  );
};
