import styled from "@emotion/styled";
let url: any = `/images/bg_member_countdown.jpg`;
let root: any = {
  waringColor: "#FDA129",
  fontCambria: "Cambria",
  bgColor: "#F0F0F0",
  bsLinkColor: "#326f4b !important",
  bsLinkHoverColor: "#128843 !important",
  firstLevelTitleFontSize: "2.2rem",
  secondaryTitleFontSize: "1.8rem",
  threeLevelTitleFontSize: "1.5rem",
  mainFontSize: "1.25rem",
  bodyFontSize: "1.125rem",
};
const styleObj: any = {
  commom: {
    H2: {
      textAlign: "center",
      fontSize: `${root.firstLevelTitleFontSize}`,
      fontFamily: `${root.fontCambria}`,
      fontWeight: "bold",
      padding: "50px 0 30px !important",
      margin: "0px",
      background: "#F0F0F0",
      "& + button ,& + a": {
        position: "absolute",
        top: "0",
        right: "0",
        fontFamily: `${root.fontCambria}`,
        fontWeight: "bold",
        fontSize: `${root.bodyFontSize}`,
        display: "none",
      },
      "&:after": {
        content: "' '",
        display: "block",
        width: "4.69rem",
        height: "5px",
        margin: "15px auto 0px",
        background: "#FDA129",
      },
    },
    NyheterDiv: {
      background: "#F0F0F0",
      "& .nyhetercontent": {
        margin: "0 auto",
        "& .content-group": {
          display: "grid",
          gridGap: "20px",
          margin: "0 auto",
          "&.columns-2": {
            gridTemplateColumns: "calc(50% - 10px) calc(50% - 10px)",
          },
          "&.columns-3": {
            gridTemplateColumns:
              "calc(33% - 10px) calc(33% - 10px) calc(33% - 10px)",
          },
          "&.columns-4": {
            gridTemplateColumns:
              "calc(25% - 10px) calc(25% - 10px) calc(25% - 10px) calc(25% - 10px)",
          },
          "& .columns-item": {
            background: "#fff",
            cursor: "pointer",

            "& .columns-item-img": {
              width: "100%",
              height: "253px",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              "& img": {
                objectPosition: "top",
                objectFit: "cover",
                transform: "scale(1)",
                transition: "transform .5s ease",
              },
            },
            "& .columns-item-details": {
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              justifyContent: "center",
              "& .details-title": {
                fontSize: `${root.threeLevelTitleFontSize}`,
                textAlign: "center",
                marginBottom: "15px",
              },
              "& .details-content": {
                fontSize: `${root.bodyFontSize}`,
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "#666",
                display: "-webkit-box",
                WebkitLineClamp: "3", // Control number of rows
                WebkitBoxOrient: "vertical",
                "& p": {
                  marginBottom: "0px",
                  wordWrap: "break-word",
                },
              },
            },
            "&:hover": {
              boxShadow:
                "0 1px 2px -2px rgba(0,0,0,.16), 0 3px 6px 0 rgba(0,0,0,.12), 0 5px 12px 4px rgba(0,0,0,.09)",
              "& .columns-item-img  img": {
                transform: "scale(1.1)",
              },
            },
          },
        },
        ".font-Cambria-blod": {
          fontFamily: `${root.fontCambria}`,
          fontWeight: "bold",
        },
        ".moreBtn": {
          textAlign: "center",
          "& .btn": {
            width: "190px",
            borderRadius: "0",
            margin: "40px 0",
            color: "#fff",
          },
        },
      },
    },
    MKDiv: {
      "&.medlemmerKlubben": {
        background: `url(${url})`,
        backgroundSize: "100% 100%",
        width: `var(--dme-container-width)`,
        marginLeft: `calc(-1*(var(--dme-container-width) - var(--dme-main-width))/2)`,
        overflow: "hidden",
        h2: {
          background: "transparent",
        },
        "& .medlemmerKlubben-body": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "50px auto 100px",
          "& .medlemmerKlubben-group": {
            width: "130px",
            height: "130px",
            background: "#fff",
            fontFamily: `${root.fontCambria}`,
            borderRadius: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "66px",
            "&:last-child": {
              marginRight: "0",
            },
            "& .item-v": {
              fontWeight: "bold",
              fontSize: "2.5rem",
              color: "#008A5B",
            },
            "& .item-t": {
              fontSize: `${root.threeLevelTitleFontSize}`,
              color: "#333",
            },
          },
        },
      },
    },
    ImageHeadingTextsDiv: {
      width: "var(--dme-container-width)",
      marginLeft:
        "calc(-1*(var(--dme-container-width) - var(--dme-main-width)) / 2)",
      minHeight: "300px",
      // margin:"0 auto",
      padding: "50px 0 50px 0",
      paddingLeft:
        "calc((var(--dme-container-width) - var(--dme-main-width)) / 2)",
      background: "#fff",
      h2: {
        textAlign: "left",
        color: "#000",
        background: "transparent",
        "&:after": {
          margin: "15px 0 0",
        },
      },
      "&>div.dm-columns": {
        height: "100%",
        gridTemplateColumns: "calc(50% - 100px) 50%!important",
        gridGap: "100px",
        "&>div.imageBox": {
          display: "flex",
          alignItems: "center",
          "& .block-container": {
            width: "100%",
          },
        },
        "&>div.blocklistBox .block-type-text": {
          fontSize: `${root.bodyFontSize}`,
          lineHeight: "1.5",
        },
      },
    },
  },
  mobile: {
    H2: {
      fontSize: "1.25rem",
      textAlign: "left",
      padding: "10px 20px !important",
      margin: "0px",
      "&:after": {
        width: "2px!important",
        height: "1.25rem !important",
        margin: "-20px 0 0 -10px !important",
      },
      "& + button,& + a": {
        display: "block",
      },
    },
    NyheterDiv: {
      "& .nyhetercontent": {
        width: "100%",
        minWidth: "100%",
        maxWidth: "100%",
        background: "#F0F0F0",
        "& .content-group": {
          gridGap: "10px",
          "&.dm-columns": {
            gridTemplateColumns: "100%",
          },
          "& .columns-item": {
            display: "grid",
            background: "#fff",
            gridTemplateColumns: "40% 60%",
            padding: "10px",
            "& .columns-item-img": {
              width: "100%",
              height: "96px",
            },
            "& .columns-item-details": {
              padding: "0 20px",
              height: "100%",
              alignItems: "flex-start",
              "& .details-title": {
                fontSize: "1.125rem",
                marginBottom: "0",
                textAlign: "left",
              },
              "& .details-content": {
                fontSize: "1rem",
                width: "100%",
              },
              "& .details-content p": {
                margin: "0",
                wordWrap: "break-word",
              },
            },
          },
        },
        ".moreBtn": {
          display: "none",
        },
      },
    },
    MKDiv: {
      "&.medlemmerKlubben": {
        width: "100%!important",
        margin: "0 auto!important",
        h2: {
          textAlign: "center",
          padding: "18px 0 !important",
          "&:after": {
            width: "4.69rem!important",
            height: "2px!important",
            margin: "15px auto 0px!important",
          },
        },
        "& .medlemmerKlubben-body": {
          width: "100%",
          minWidth: "100%",
          maxWidth: "100%",
          padding: "0 15px",
          gap: "15px",
          margin: "10px auto 30px",
          "& .medlemmerKlubben-group": {
            width: "60px",
            height: "60px",
            marginRight: "0px",
            "& .item-v": {
              fontSize: "1.25rem",
            },
            "& .item-t": {
              fontSize: "12px",
            },
          },
        },
      },
    },
    ImageHeadingTextsDiv: {
      minWidth: "100%",
      padding: "0px!important",
      width: "100%!important",
      margin: "0 auto!important",
      h2: {
        background: "#F0F0F0",
        "& + button,& + a": {
          display: "none",
        },
      },

      "&>div.dm-columns": {
        gridTemplateColumns: "100%!important",
        gridGap: "0",
        "& .blocklistBox>div": {
          "&>div:nth-of-type(2)": {
            padding: "10px",
          },
          // "&>div:last-child":{
          //   p:{
          //     marign:"0"
          //   },
          //   "& a.btn":{
          //     display:"none"
          //   }
          // }
        },
        "&>div.blocklistBox .block-type-text": {
          fontSize: "1rem",
        },
        "&>div.imageBox": {
          padding: "10px",
        },
      },
    },
  },
  pad: {
    NyheterDiv: {
      "& .nyhetercontent": {
        width: "100%",
        minWidth: "100%",
        maxWidth: "100%",
        background: "#F0F0F0",
      },
    },
    MKDiv: {
      "&.medlemmerKlubben": {
        width: "100%!important",
        margin: "0 auto!important",
        h2: {
          textAlign: "center",
          padding: "18px 0 !important",
        },
        "& .medlemmerKlubben-body": {
          width: "100%",
          minWidth: "100%",
          maxWidth: "100%",
          padding: "0 15px",
          margin: "15px auto 50px",
          "& .medlemmerKlubben-group": {
            width: "130px",
            height: "130px",
            // "& .item-v":{
            //   fontSize: "2.5rem",
            // },
            // "& .item-t":{
            //   fontSize: "1.5rem",
            // }
          },
        },
      },
    },
    ImageHeadingTextsDiv: {
      minWidth: "100%",
      padding: "0px!important",
      width: "100%!important",
      margin: "0 auto!important",
      h2: {
        background: "#F0F0F0",
        "& + button,& + a": {
          display: "none",
        },
      },

      "&>div.dm-columns": {
        gridTemplateColumns: "100%!important",
        gridGap: "0",
        "& .blocklistBox>div": {
          "&>div:nth-of-type(2)": {
            padding: "10px",
          },
          // "&>div:last-child":{
          //   p:{
          //     marign:"0"
          //   },
          //   "& a.btn":{
          //     display:"none"
          //   }
          // }
        },
        "&>div.imageBox": {
          padding: "10px",
        },
      },
    },
  },
};

export const H2: any = styled.h2(
  {
    ...styleObj.commom.H2,
    // mobileStyle
    ".dme-viewmode-mobile &": {
      ...styleObj.mobile.H2,
    },
    "@media(max-width: 750px)": {
      ...styleObj.mobile.H2,
    },
  },
  (props: any) => ({
    color: props.white ? "#fff" : "#000",
  })
);

export const MKDiv: any = styled.div(
  {
    ...styleObj.commom.MKDiv,
    "@media(min-width: 1200px)": {
      "& .medlemmerKlubben-body": {
        // width:"80%",
        // maxWidth: "100%",
        // minWidth: "1200px",
        // width:"62.5vw",
        // maxWidth: "1600px",
      },
    },
    "@media(max-width: 1200px)": {
      ...styleObj.pad.MKDiv,
    },
    "@media(max-width: 750px)": {
      ...styleObj.mobile.MKDiv,
    },

    //padStyle
    ".dme-viewmode-tablet &": {
      ...styleObj.pad.MKDiv,
    },
    // mobileStyle
    ".dme-viewmode-mobile &": {
      ...styleObj.mobile.MKDiv,
    },
  },
  (props: any) => ({
    "& .medlemmerKlubben-body": {
      // width:props.width<1200?`${props.width}px !important`:'1200px',
      // minWidth: props.width<1200?`${props.width}px!important`:'1200px',
      // maxWidth: props.width<1200?`${props.width}px!important`:'1200px',
    },
  })
);

export const NyheterDiv: any = styled.div(
  {
    ...styleObj.commom.NyheterDiv,
    "@media(max-width: 750px)": {
      ...styleObj.mobile.NyheterDiv,
    },
    "@media(max-width: 1200px)": {
      ...styleObj.pad.NyheterDiv,
    },
    "@media(min-width: 1200px)": {
      "& .nyhetercontent": {
        // minWidth: "1200px",
        // width:"62.5vw",
        // maxWidth: "1600px",
      },
    },

    //padStyle
    ".dme-viewmode-tablet &": {
      ...styleObj.pad.NyheterDiv,
    },
    // mobileStyle
    ".dme-viewmode-mobile &": {
      ...styleObj.mobile.NyheterDiv,
    },
  },
  (props: any) => ({
    // width:props.width<1200?`${props.width}px !important`:'1200px',
    // minWidth: props.width<1200?`${props.width}px!important`:'1200px',
    // maxWidth: props.width<1200?`${props.width}px!important`:'1200px',
  })
);

export const ImageHeadingTextsDiv: any = styled.div(
  {
    ...styleObj.commom.ImageHeadingTextsDiv,

    "@media (max-width: 1200px)": {
      ...styleObj.pad.ImageHeadingTextsDiv,
    },
    "@media(max-width: 750px)": {
      ...styleObj.mobile.ImageHeadingTextsDiv,
    },
    "@media(min-width: 1200px)": {
      // minWidth: "1200px",
      // width:"62.5vw",
    },

    //padStyle
    ".dme-viewmode-tablet &": {
      ...styleObj.pad.ImageHeadingTextsDiv,
    },
    // mobileStyle
    ".dme-viewmode-mobile &": {
      ...styleObj.mobile.ImageHeadingTextsDiv,
    },
  },
  (props: any) => ({
    // width:props.width<1200?`${props.width}px !important`:'1200px',
    // minWidth: props.width<1200?`${props.width}px!important`:'1200px',
    // maxWidth: props.width<1200?`${props.width}px!important`:'1200px',
  })
);
