import { DMEData, DMEditor, DMEditorRefType } from "dmeditor";
import { useEffect, useRef, useState } from "react";
import { FetchWithAuth } from "digimaker-ui/util";
import { useNavigate } from "react-router";
import { dmeditorFields } from "./fieldConfig";
import { pageSettings, pageSettingsPage } from "./pageSetting";
import { useSearchParams } from "react-router-dom";
import { getArticleSummaryFromList } from "../../../components/util";
import { convertObject } from "shared/src/widgets/util";

export const Edit = (props: { id: number }) => {
  const editorRef = useRef<DMEditorRefType>(null);
  const nav = useNavigate();
  const [searchParams] = useSearchParams();
  let contentType = "";

  const save = (data: DMEData.SavedData) => {
    const postData = {
      coverimage: data.page.cover_image || "",
    };
    if (contentType !== "site") {
      postData["title"] = data.page.title;
    }

    postData[dmeditorFields[contentType]] = data.data;
    if (contentType === "article") {
      const summaryHtml = getArticleSummaryFromList(data.data);
      postData["summary"] = summaryHtml;
    }

    if (contentType === "page") {
      if (data.page.hide_in_menu) {
        postData["hide_in_menu"] = 1;
      } else {
        postData["hide_in_menu"] = 0;
      }
    }

    FetchWithAuth(`content/update/${props.id}`, {
      method: "POST",
      body: JSON.stringify(postData),
    }).then((data) => {
      if (data.error === false) {
        window.alert("Saved. Click to return");
        if (searchParams.has("from")) {
          nav(("/website" + searchParams.get("from")) as string);
        } else {
          nav(`/website/main/${props.id}`);
        }
      } else {
        window.alert("Error: " + data.data.message);
      }
    });
  };

  const cancel = () => {
    if (searchParams.has("from")) {
      nav(("/website" + searchParams.get("from")) as string);
    } else {
      nav(`/website/main/${props.id}`);
    }
  };

  useEffect(() => {
    FetchWithAuth(`content/get/${props.id}`)
      .then((data) => {
        if (data.error === false) {
          contentType = data.data.metadata.contenttype;
          const list = data.data[dmeditorFields[contentType]];
          let page: any = {
            title: data.data.title,
            cover_image: data.data.coverimage,
          };
          if (contentType === "page") {
            page = {
              ...page,
              hide_in_menu: data.data.hide_in_menu ? true : false,
            };
          }
          const current = editorRef.current;
          if (current) {
            current.setPageSettings(
              (contentType === "page" ? pageSettingsPage : pageSettings) as any
            );
          }
          current?.setData(convertObject(list || []));
          current?.setPageData(page);
        }
      })
      .catch((d) => {
        window.alert(d.message);
        nav(`/`);
      });
  }, [props.id]);

  return <DMEditor ref={editorRef} onSave={save} onCancel={cancel} />;
};
