import { Form } from "react-bootstrap";
import { EntityClubList } from "./entity";
import { DME } from "dmeditor";
import { useEffect, useState } from "react";
import { StyleDiv } from "./style";
import { siteEnv } from "../siteEnv";

const ClubDropdown = (props: DME.WidgetRenderProps<EntityClubList>) => {
  const {
    blockNode: { data },
  } = props;

  const [clubs, setClubs] = useState(data.clubs);

  useEffect(() => {
    if (!props.blockNode.serverData) {
      const kretsID = siteEnv.kretsID;
      if (!kretsID) {
        return;
      }
      fetch(
        "https://www.bridge.no/bridgemodule/klubber_json?kretsid=" + kretsID
      )
        .then((res) => res.json())
        .then((data: any) => {
          setClubs(data.list);
        });
    }
  }, []);

  return (
    <StyleDiv>
      <div className={"klubber"}>
        <div className="w-container" style={{ display: "flex" }}>
          <div className="font-Cambria-blod klubberTitle">
            {data.title.toUpperCase()}
          </div>
          <div className="klubbergroup">
            <Form className="d-flex p-relative klubber-item">
              <Form.Select
                onChange={(e) => {
                  if (e.target.value) {
                    window.location.href =
                      "https://bridge.no/bridgemodule/klubbredirect/" +
                      e.target.value;
                  }
                }}
              >
                <option>Velg klubb</option>
                {clubs.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </Form.Select>
            </Form>
          </div>
        </div>
      </div>
    </StyleDiv>
  );
};

const onServerSideLoad = async (blockData, context) => {
  const { siteEnv } = context;
  const kretsID = siteEnv.kretsID;
  let clubs = [];
  if (kretsID) {
    const res = await fetch(
      "https://www.bridge.no/bridgemodule/klubber_json?kretsid=" + kretsID
    );
    const jsonData = await res.json();
    clubs = jsonData.list;
  }
  blockData.data.clubs = clubs;
  blockData.serverData = true;
};

export { ClubDropdown, onServerSideLoad };
