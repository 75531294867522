import { useRef, useState } from "react";
import { Button, Overlay, Alert } from "react-bootstrap";
import useSiteStore from "../store/useSiteStore";
import { Space } from "./Space";

export const ShowPageUrl = (props: { content: any }) => {
  const { content } = props;

  const [urlShown, setUrlShown] = useState(false);
  const urlShowButton = useRef(null);
  const {
    site: { host, basePath },
  } = useSiteStore();

  const getUrlPath = () => {
    return content.location.identifier_path.split("/").slice(4).join("/");
  };

  const getAbsUrl = () => {
    return (
      "https://" + (host || "www.bridge.no/" + basePath) + "/" + getUrlPath()
    );
  };

  return (
    <>
      <Button
        ref={urlShowButton}
        variant="link"
        onClick={() => setUrlShown(!urlShown)}
      >
        <i className="bi bi-link-45deg"></i>Vis url
      </Button>
      <Overlay
        target={urlShowButton.current}
        show={urlShown}
        placement="bottom"
        rootClose={true}
        onHide={() => setUrlShown(false)}
      >
        <div
          style={{
            marginTop: 5,
            padding: 10,
            maxWidth: 300,
            border: "1px solid #dddddd",
            background: "white",
          }}
        >
          <div>
            <div>Intern url:</div>
            <input
              type="text"
              size={25}
              onFocus={(e) => e.target.select()}
              readOnly
              value={"./" + getUrlPath()}
            />
          </div>
          <div style={{ marginTop: 4 }}>
            <div>Absolutt url:</div>
            <input
              type="text"
              size={25}
              onFocus={(e) => e.target.select()}
              readOnly
              value={getAbsUrl()}
            />
          </div>
          <Space />
          <a href={getAbsUrl()} target="_blank">
            Åpen
          </a>
          <Space />
          <Alert>
            Merk: anbefaler å bruke intern url når det er en lenke fra intern
            siden, slik at koblingen ikke påvirkes av domeneendringer.
          </Alert>
        </div>
      </Overlay>
    </>
  );
};
