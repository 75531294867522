import * as React from "react";
import {
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Calendar } from "react-calendar";
import { format, isSameDay } from "date-fns";
import { CalendarEvent, EntityCalenderWidget } from "./entity";

import { DME, useDevice } from "dmeditor";
import { FetchWithAuth } from "digimaker-ui/util";
import { useEditorStore } from "dmeditor";
import { CalendarStyle } from "./style";
import { serverFetchWithAuth } from "../util";
import { siteEnv } from "../siteEnv";

const { useState, useEffect } = React;

const commonEventParent = 1141;

const CalenderWidget = (props: DME.WidgetRenderProps<EntityCalenderWidget>) => {
  const {
    blockNode: {
      data: { settings },
    },
  } = props;

  const { updateSelectedBlock } = useEditorStore();
  const [width, setWidth] = useState(settings.width ?? 600);
  const [position, setPosition] = useState(settings.position ?? "right");
  const [height, setHeight] = useState(settings.height ?? 300);
  const [header, setHeader] = useState(settings.header ?? "Upcoming events");
  const [events, setEvents] = useState<CalendarEvent[]>(
    props.blockNode.data.value || []
  );
  const [value, setValue] = useState<Date | [Date | null, Date | null]>(
    new Date()
  );
  const [currentMonth, setCurrentMonth] = useState(
    format(new Date(), "MMMM yyyy")
  );
  const [hoveredEvents, setHoveredEvents] = useState<CalendarEvent[]>([]);
  const device = useDevice();
  const isMobile = device === "mobile";
  const [filterTypes, setFilterTypes] = useState({
    category1: true,
    category2: true,
    category3: true,
    category4: true,
  });

  useEffect(() => {
    const fetchEvents = async () => {
      const eventParent = siteEnv.folderEvents;

      try {
        const responses = await Promise.all([
          FetchWithAuth("content/list/event?parent=" + eventParent),
          FetchWithAuth("content/list/event?parent=" + commonEventParent),
        ]);

        const successfulResponses = responses.filter(
          (response) => !response.error
        );
        if (successfulResponses.length > 0) {
          const combinedEvents = successfulResponses.flatMap(
            (response) => response.data.list
          );
          setEvents(combinedEvents);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    if (!props.blockNode.serverData) {
      fetchEvents();
    }
    setHeight(settings.height ?? 300);
    setWidth(settings.width ?? 600);
    setHeader(settings.header ?? "Upcoming Events");

    const handleResize = () => {
      const currentWidth = window.innerWidth;
      const newIsMobile = currentWidth < 768;
      // setIsMobile(newIsMobile);
      setWidth(newIsMobile ? currentWidth - 20 : settings.width ?? 600);
      setHeight(newIsMobile ? currentWidth / 2 : settings.height ?? 300);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [settings]);

  const backgroundColorMapping = {
    [CalendarStyle.category1Tile]: "#FFFFE0",
    [CalendarStyle.category2Tile]: "#ADD8E6",
    [CalendarStyle.category3Tile]: "#90EE90",
    [CalendarStyle.category4Tile]: "#aaa",
    [CalendarStyle.diverseEventTile]: "#ff0", // Standardgrå
  };

  const getEventClassName = (event) => {
    const styleMapping = {
      category1: CalendarStyle.category1Tile,
      category2: CalendarStyle.category2Tile,
      category3: CalendarStyle.category3Tile,
      category4: CalendarStyle.category4Tile,
      defaultCategory: CalendarStyle.diverseEventTile,
    };

    return event.category
      ? styleMapping[event.category.value] || styleMapping.defaultCategory
      : styleMapping.defaultCategory;
  };

  const renderFilterOptions = () => {
    const uniqueCategories = Array.from(
      new Set(events.map((event) => event.category.value))
    );

    return (
      <div className={CalendarStyle.filterContainer}>
        {uniqueCategories.map((categoryValue) => {
          const event = events.find((event) =>
            event.category ? event.category.value === categoryValue : false
          );
          const className = getEventClassName(event);

          return (
            <label
              key={categoryValue}
              className={className}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "5px 10px",
                borderRadius: "5px",
              }}
            >
              <input
                type="checkbox"
                checked={filterTypes[categoryValue]}
                onChange={() =>
                  setFilterTypes((prev) => ({
                    ...prev,
                    [categoryValue]: !prev[categoryValue],
                  }))
                }
              />
              {categoryValue}
            </label>
          );
        })}
      </div>
    );
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const eventsToday = events.filter(
        (event) =>
          isSameDay(new Date(event.date), date) &&
          filterTypes[event.category.value]
      );
      if (eventsToday.length > 1) {
        // Hvis det er flere enn én hendelse
        return CalendarStyle.diverseEventTile; // Returnerer klassenavn for grå bakgrunnsfarge
      } else if (eventsToday.length === 1) {
        return getEventClassName(eventsToday[0]); // Eksisterende logikk for en enkelt hendelse
      }
    }
    return null;
  };

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const eventsToday = events.filter(
        (event) =>
          isSameDay(new Date(event.date), date) &&
          filterTypes[event.category.value]
      );
      if (eventsToday.length > 0) {
        return (
          <div
            onMouseEnter={() => setHoveredEvents(eventsToday)} // Endre til å sette en liste av hendelser
            onMouseLeave={() => setHoveredEvents([])} // Tøm listen ved mouse leave
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {/* Optional visual indicators */}
          </div>
        );
      }
    }
    return null;
  };

  // funksjon for endring ev bredde
  const updateWidth = (e, v) => {
    updateSelectedBlock<EntityCalenderWidget>((data) => {
      data.settings.width = v;
    });
  };

  // funksjon for endring ev høyde
  const updateHeight = (e, v) => {
    updateSelectedBlock<EntityCalenderWidget>((data) => {
      data.settings.height = v;
    });
  };

  return (
    <div
      className={CalendarStyle.widgetStyle}
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <h2 style={{ textAlign: "center", fontWeight: "bold", padding: "10px" }}>
        {header}
      </h2>

      <div
        className={`${CalendarStyle.container} ${
          isMobile ? CalendarStyle.mobileWidgetStyle : ""
        }`}
      >
        {position !== "hideEventList" && (
          <div
            className={`${CalendarStyle.boxStyle} ${
              isMobile ? CalendarStyle.mobileEventsStyle : ""
            }`}
          >
            <h3>{currentMonth}</h3>
            <ul className={CalendarStyle.listStyle}>
              {events
                .filter((event) => {
                  const eventDate = new Date(event.date);
                  const eventMonth = eventDate.toLocaleString("default", {
                    month: "long",
                  });
                  const eventYear = eventDate.getFullYear().toString();
                  return (
                    eventMonth === currentMonth.split(" ")[0] &&
                    eventYear === currentMonth.split(" ")[1] &&
                    filterTypes[event.category.value]
                  );
                })
                .map((event: CalendarEvent) => (
                  <li
                    key={event.id}
                    className={getEventClassName(event)}
                    style={{
                      padding: "5px 10px",
                      borderRadius: "5px",
                      marginTop: "5px",
                    }}
                  >
                    <strong>{event.title}</strong> -{" "}
                    {new Date(event.date).toLocaleDateString()}
                    <p>{event.description}</p>
                  </li>
                ))}
            </ul>
          </div>
        )}

        {position !== "hideCalendar" && (
          <div
            className={`${CalendarStyle.calender} ${
              isMobile ? CalendarStyle.mobileCalenderStyle : ""
            }`}
          >
            <div>{renderFilterOptions()}</div>
            <Calendar
              onChange={(nextValue) => {
                if (nextValue !== null) {
                  setValue(nextValue);
                }
              }}
              onActiveStartDateChange={({ activeStartDate }) => {
                if (activeStartDate !== null) {
                  setCurrentMonth(format(activeStartDate, "MMMM yyyy"));
                }
              }}
              value={value}
              locale="nb-NO"
              tileContent={tileContent}
              tileClassName={tileClassName}
            />
            {hoveredEvents.length > 0 && (
              <div
                className="event-card"
                style={{
                  position: "absolute",
                  zIndex: 10,
                  border: "1px solid black",
                  top: "30%",
                  right: "40%",
                }}
              >
                {hoveredEvents.map((event, index) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor:
                        backgroundColorMapping[getEventClassName(event)],
                      padding: "5px",
                    }}
                  >
                    <h3>{event.title}</h3>
                    <p>Date: {new Date(event.date).toLocaleDateString()}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
const onServerSideLoad = async (blockData: any, context: any) => {
  const { siteEnv } = context;
  const parent = siteEnv.folderEvents;
  const bodyObj = `query{
      event(filter:[{_location_parent_id:${parent}}, {_location_parent_id: ${commonEventParent}}],limit:-1, sort:["date desc", "published desc"]){
        id,
        title,
        date,
        description,
        category,
        location{id,parent_id}, 
    }
    }`;
  const jsonData = await serverFetchWithAuth(bodyObj);
  const list = jsonData.data.event;
  for (const i in list) {
    list[i].category = { text: list[i].category, value: list[i].category };
  }
  console.log(list);
  blockData.data.value = list;
  blockData.serverData = true;
};

export { CalenderWidget, onServerSideLoad };
