import { useMemo } from "react";
import { SpillRecord } from "./entity";
import { InputCard } from "./InputCard";
import { CardTypeLabel, PositionPanelRow } from "./style";
import { css } from "@emotion/css";

export const PositionPanel = (props: {
  data: SpillRecord;
  mode: "edit" | "view";
  position: "north" | "south" | "east" | "west";
  onChange: (data: SpillRecord) => void;
  restData: { spade: string; heart: string; diamond: string; club: string };
}) => {
  const { data, onChange, mode, restData } = props;

  const getUpdateData = (
    v: string,
    card: "spade" | "heart" | "diamond" | "club"
  ) => {
    const newData = {
      ...data,
      [card]: { hidden: data[card].hidden, data: v },
    };
    return newData;
  };

  const getUpdatedChecked = (
    hidden: boolean,
    card: "spade" | "heart" | "diamond" | "club"
  ) => {
    const newData = {
      ...data,
      [card]: { hidden: hidden, data: data[card].data },
    };
    return newData;
  };

  const isAllChecked = useMemo(() => {
    return (
      !data.spade.hidden &&
      !data.heart.hidden &&
      !data.diamond.hidden &&
      !data.club.hidden
    );
  }, [data]);

  const setAllHidden = (hidden: boolean) => {
    const newData = {
      spade: { ...data.spade, hidden: hidden },
      heart: { ...data.heart, hidden: hidden },
      diamond: { ...data.diamond, hidden: hidden },
      club: { ...data.club, hidden: hidden },
    };
    props.onChange(newData);
  };

  const symbols = { spade: "♠️", heart: "♥️", diamond: "♦️", club: "♣️" };

  return (
    <div
      style={
        props.position === "west"
          ? {
              marginLeft: "auto",
              marginRight: 0,
              width: "fit-content",
            }
          : {}
      }
    >
      {mode === "edit" && (
        <div>
          <label>
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={(e) => setAllHidden(!e.target.checked)}
            />
            <span>&nbsp;Vis</span>
          </label>
        </div>
      )}

      {["spade", "heart", "diamond", "club"].map((card) => (
        <PositionPanelRow style={mode === "edit" ? { padding: "2px 0" } : {}}>
          {mode === "edit" && (
            <>
              <label
                className={css`
                  display: flex;
                `}
              >
                <input
                  type="checkbox"
                  checked={!data[card].hidden}
                  onChange={(e) =>
                    props.onChange(
                      getUpdatedChecked(!e.target.checked, card as any)
                    )
                  }
                />
                <CardTypeLabel className={"card-" + card}>
                  {symbols[card]}
                </CardTypeLabel>
              </label>
              <InputCard
                value={data[card].data}
                onChange={(v) => props.onChange(getUpdateData(v, card as any))}
                otherValue={restData[card]}
              />
            </>
          )}
          {mode === "view" && !data[card].hidden && (
            <>
              <CardTypeLabel className={"card-" + card}>
                {symbols[card]}
              </CardTypeLabel>
              <span className="card-value">{data[card].data}</span>
            </>
          )}
        </PositionPanelRow>
      ))}
    </div>
  );
};
